import React from 'react';
import Article, { SubTitle } from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import img from 'img/help/segments-notifications/notifications2.png';
import img1 from 'img/help/segments-notifications/addNotifications.png';
import img2 from 'img/help/segments-notifications/options.png';
import img3 from 'img/help/segments-notifications/turnoff.png';

const Content = () => {
  return (
    <div>
      <SubTitle>What is segment summary?</SubTitle>
      <p>
        Segment summary email can be useful to get daily or weekly updates of the segments that
        you’re following. It contains a summary of segments (grouped to a website) with number of
        sessions and percentage change in a daily or weekly period (depending on the your
        configuration).
      </p>
      <Image
        src={img}
        lazy
        alt="Segments notifications email - preview"
        title="Segments summary email"
      />
      <SubTitle>How it works?</SubTitle>
      <p>
        Everyday and every Monday (for a weekly notifications) at 5AM UTC we calculate the number of
        sessions in segments and send you an email with the summary. Depending on your settings, the
        number of sessions represent:
      </p>
      <ul>
        <li>
          last day from 00:00 UTC to 23:59 UTC (for a <strong>daily notifications</strong>)
        </li>
        <li>
          last Monday from 00:00 UTC to Sunday 23:59 UTC (for a{' '}
          <strong>weekly notifications</strong>)
        </li>
      </ul>
      <p className="alert alert-primary mb-5 mt-5" role="alert">
        <strong>Important</strong>: Values are calculated for a UTC timezone. The number of sessions
        can vary from the values in application for the same period. LiveSession application shows
        sessions based on your current timezone.
      </p>

      <SubTitle>How to add or remove segment?</SubTitle>
      <p>You can add or remove segments in two different way:</p>
      <ol>
        <li>
          <p>By clicking on the “Add to summary” button on sessions list:</p>
          <Image
            src={img1}
            lazy
            alt="Add to summary - button"
            title="Add to summary on sessions list"
          />
          <p>Current selected segment will be added for a to segments summary email. </p>
        </li>
        <li>
          <p>
            By adding or removing a segment from a list of following segments in “Settings &gt;
            Notifications”:
          </p>
        </li>
      </ol>
      <Image
        src={img2}
        lazy
        alt="LiveSession App - Select segments"
        title="Select segments from a list"
      />

      <p className="alert alert-primary mb-5 mt-5" role="alert">
        <strong>Important</strong>: Segments are linked with a website. If you want to get segment
        summary for a multiple websites, you have to add it for each one.
      </p>
      <SubTitle>How to change frequency or turn off notifications? </SubTitle>
      <p>To turn off notifications switch the button in “Settings &gt; Notifications”:</p>
      <Image
        src={img3}
        lazy
        alt="LiveSession App - turn off segments summary"
        title="Turn off segments notifications"
      />
      <p>
        To change the frequency, select “<strong>daily</strong>” or “<strong>weekly</strong>”
        notifications.
      </p>
      <p>Confirm the change by clicking “Save changes” button.</p>
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-use-inspect-mode/',
    title: 'How to use Inspect Mode?',
    description:
      'During session replay, pick a specific element and search through recordings to learn how people interact with your website.',
  },
  {
    link: '/help/how-to-record-keystrokes/',
    title: "How to record user's keystrokes?",
    description: 'See how to enable global keystroke tracking.',
  },
];

export const frontmatter = {
  metaTitle: 'How to set segment summary email?',
  metaDescription: 'Get a daily or weekly email summary of segments that you follow. ',
  canonical: '/help/how-to-set-segment-summary-email/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Product" title={frontmatter.metaTitle} {...frontmatter} />
);
